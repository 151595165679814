const combineLink = (...args) => {
    let link = "";

    //args.forEach(value => value && (link += `${value}/`))
    args.forEach(
        (value) =>
            value &&
            (link += `${
                String(value).includes("local:300")
                    ? value.replace(
                          "http://www.gabriel.local:3001/",
                          "https://izum05.ru",
                      )
                    : value
            }/`),
    );

    return link.substr(0, link.length - 1);
};

export default combineLink;
