import combineLink from "helper/combineLink";
import {api,request} from "helper";

class AddressService {
    fetchAddress = async (data) => {
        let options = {
            setLoading: false,
            withLoader: false,
            query: data
        }

        return request.get(combineLink(api.userAdress), options)
    };

    createAddress = async (data) => {
        //console.log('data', data)

        let options = {
            setLoading: false,
            withLoader: false,
            body: data
        }

        return request.post(combineLink(api.userAdress), options)
    };

    deleteAddress = async (id) => {
        let options = {
            setLoading: false,
            withLoader: false,
        }

        return request.delete(combineLink(api.userAdress, id), options)
    };

    updateAddress = async (data, id) => {
        let options = {
            setLoading: false,
            withLoader: false,
            body: data
        }

        return request.put(combineLink(api.userAdress, id), options)
    };
}
const addressService = new AddressService();
export default addressService
