export const FETCH_REVIEWS_START = 'FETCH_REVIEWS_START';
export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';

export const CREATE_REVIEWS_START = 'CREATE_REVIEWS_START';
export const CREATE_REVIEWS_SUCCESS = 'CREATE_REVIEWS_SUCCESS';
export const CREATE_REVIEWS_FAILURE = 'CREATE_REVIEWS_FAILURE';

export const UPLOAD_REVIEW_PHOTO_START = 'UPLOAD_REVIEW_PHOTO_START';
export const UPLOAD_REVIEW_PHOTO_SUCCESS = 'UPLOAD_REVIEW_PHOTO_SUCCESS';
export const UPLOAD_REVIEW_PHOTO_FAILURE = 'UPLOAD_REVIEW_PHOTO_FAILURE';

