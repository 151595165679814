import { createSelector } from 'reselect';
import { directionsState } from './reducer';

export const restaurantsCategoriesSelector = (state: any): directionsState =>
  state.restaurantsCategories;

export const restaurantsCategoriesListSelector = createSelector(
  restaurantsCategoriesSelector,
  (state) => state.list
);

export const activeIdSelector = createSelector(
  restaurantsCategoriesSelector,
  (state) => state.activeId
);

export const activeDirectionIdSelector = createSelector(
  restaurantsCategoriesSelector,
  (state) => state.currentDirection
);

export const activeSpecializationSelector = createSelector(
  restaurantsCategoriesSelector,
  (state) => state.currentSpecialization
);

export const activeDirectionSelector = createSelector(
  [restaurantsCategoriesListSelector, activeIdSelector],
  (directions, activeId) => directions.find((direction) => direction.uniqId === activeId)
);

export const directionsSelector = createSelector(
  restaurantsCategoriesListSelector,
  (list) => list.filter((item) => !!item.position)
);

export const activeDirectionNameSelector = createSelector(
  [restaurantsCategoriesListSelector, activeDirectionSelector],
  (restaurantsCategories, activeCategory = {}) => {
    const MAIN_TITLE = 'Рестораны';
    const [firstDirection = {}] = restaurantsCategories;
    const isDirection = 'position' in activeCategory;

    if (activeCategory?.name === firstDirection.name || !isDirection) {
      return MAIN_TITLE;
    }

    return activeCategory?.name || MAIN_TITLE;
  }
);
