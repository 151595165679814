import mapValues from 'lodash/mapValues'

const baseApi: string = process.env.NEXT_PUBLIC_API_URL;

const createLinks = (links: {}):object => mapValues(links, ((link: string) => `${baseApi}${link}`))

const api: any = createLinks({
    partners: 'partner',
    categoryList: '',
    requestAuth: 'user/auth',
    requestCodeWithCaptcha: 'user/requestCodeFromWeb',
    requestCode: 'user/requestCode',
    user: 'user',
    order: 'order',
    city: 'city',
    cart: 'cart',
    direction: 'direction',
    profile: 'user/profile',
    banner: 'banner',
    specialPromoGifts: 'partner/specialPromoGifts?random=1',
    promoGifts: 'partner/promoGifts',
    userPromoCode: 'user/profilePromo',
    promoCode: 'user/promo',
    userAdress: 'user/address',
    review: 'review',
    userBonus: 'user/bonus',
    searchPartner: 'partner/search',
    staticPage: 'page',
})


export default api
