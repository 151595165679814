const defineAndGetModule = (module1, module2) => {
    switch (process.env.NEXT_PUBLIC_IS_MINI_SITE) {
        case "true":
            return module2;
            break;

        default:
            return module1;
    }
};

export default defineAndGetModule;
