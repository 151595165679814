import { useCallback } from "react";
import Alert from "shared/Alert";
import { declensions } from "helper/declension";
import combineLink from "helper/combineLink";
import { Dispatch } from "redux";
import { removeCartItem, addToCart } from "modules/Cart/store/cart/actions";
import { useRouter } from "next/router";
import { links } from "helper";

const useMessage = () => {
    const router = useRouter();
    const navigation = {
        navigate: (path, query?) =>
            router.push({
                pathname: path,
                query: { ...query },
            }),
    };

    const showCleanCartMessage = useCallback(
        (): Promise<boolean> =>
            new Promise((resolve) => {
                Alert.alert(
                    "",
                    "Все ранее добавленное в корзину будет удалено. Продолжить?",
                    [
                        {
                            text: "Ок",
                            onPress: resolve.bind(null, true),
                        },
                        {
                            text: "Отмена",
                            onPress: resolve.bind(null, false),
                        },
                    ],
                    { cancelable: false },
                );
            }),
        [],
    );

    const showDeliveryNotAvailableMessage = useCallback((address, partner) => {
        if (!address) {
            return;
        }

        const { name, timeOut, isClosed } = partner;
        const restaurantName = name;
        const timeBeforeOpening = timeOut;

        if (isClosed) {
            Alert.alert(
                "",
                `В данный момент ${restaurantName} по адресу ${address} не доставляет. Заведение откроется через ${timeBeforeOpening}`,
                [{ text: "Ok", onPress: () => {}, style: "cancel" }],
                { cancelable: false },
            );
        }
    }, []);

    const isShowingALert = (
        cart: { partner: { id: any } },
        partner: { id: any },
    ): Promise<boolean> => cart.partner && partner.id !== cart.partner.id;

    const isChangedPartnerId = async (cart: any, partner: any) => {
        //console.log('cartpartner', cart, partner)

        if (isShowingALert(cart.data, partner)) {
            return await showCleanCartMessage();
        }
        return new Promise((resolve) => resolve(true));
    };

    const getRequiredModifiers = (productOpen) =>
        productOpen.modifiers?.filter((item) => item.min_amount > 0);

    const haveRequiredModifiers = (productOpen, modifiers) => {
        const notHaveModArr = getRequiredModifiers(productOpen).filter(
            (item) => {
                const findArr = item.modifiers.filter((mod) =>
                    modifiers?.split(",").includes(mod.id),
                );
                return !findArr.length;
            },
        );
        return !!notHaveModArr.length;
    };

    const showChoiceOptionsMessage = (productOpen, modifiers) => {
        if (haveRequiredModifiers(productOpen, modifiers)) {
            Alert.alert(
                "Выберите пожалуйста опции:",
                getRequiredModifiers(productOpen)
                    .map((item) => item.name)
                    .join(", "),
                [{ text: "Выбрать", onPress: () => {}, style: "cancel" }],
                { cancelable: false },
            );
            return true;
        }
        return false;
    };

    const showAuthorizationMessage = (partnerId) => {
        Alert.alert(
            "",
            'Чтобы добавить "Блюдо за баллы" в корзину необходимо авторизоваться',
            [
                {
                    text: "Авторизация",
                    onPress: () => {
                        navigation.navigate(links.abs.authRequest, {
                            place: "partner",
                            partner_id: partnerId,
                        });
                    },
                },
                { text: "Отмена", onPress: () => {}, style: "cancel" },
            ],
            { cancelable: false },
        );
    };

    const showNotEnoughPointsMessage = (pointsPrice, userPoints) => {
        const difference = pointsPrice - userPoints;
        Alert.alert(
            "",
            `Вам не хватает ${difference} ${declensions(difference, [
                "балла",
                "баллов",
                "баллов",
            ])} для оплаты этого блюда. Количество накопленных баллов вы можете посмотреть в Личном кабинете`,
            [
                {
                    text: "Личный кабинет",
                    onPress: () => {
                        requestAnimationFrame(() => {
                            navigation.navigate(links.abs.profile);
                        });
                    },
                },
                { text: "Отмена", onPress: () => {}, style: "cancel" },
            ],
            { cancelable: false },
        );
    };

    const useReplacePointsDishMesssage = (
        zoneId: number,
        productIdInCart: any,
        values: {
            quantity: number;
            productId: number;
            type: number;
            modifiers: any;
            zoneId: number;
        },
        dispatch: Dispatch<any>,
        onReplacingEnded?: () => void,
    ) => {
        Alert.alert(
            "",
            "В корзине уже есть одно блюдо за баллы. Заменить его?",
            [
                {
                    text: "Да",
                    onPress: async () => {
                        await dispatch(removeCartItem(productIdInCart, zoneId));
                        dispatch(addToCart(values)).then(
                            () => !!onReplacingEnded && onReplacingEnded(),
                        );
                    },
                },
                { text: "Нет", onPress: () => {}, style: "cancel" },
            ],
            { cancelable: false },
        );
    };

    const showMinSumForPoint = (isProductForPoint, checkMinSum, id) => {
        Alert.alert(
            ``,
            `Минимальная сумма заказа ${
                isProductForPoint ? "при наличии товара за баллы" : ""
            } ${checkMinSum} руб. Дополните корзину.`,
            [
                {
                    text: "Перейти в меню",
                    onPress: () =>
                        navigation.navigate(
                            combineLink(links.abs.partners, id),
                        ),
                    style: "cancel",
                },
                { text: "Закрыть", onPress: () => {}, style: "cancel" },
            ],
            { cancelable: false },
        );
    };

    const showNotAdress = (partner) => {
        Alert.alert(
            `Укажите адрес, куда доставить заказ`,
            `Это нужно, чтобы точно посчитать стоимость доставки`,
            [
                {
                    text: "Указать адрес",
                    onPress: () =>
                        navigation.navigate(links.abs.map, {
                            return_url: location.pathname,
                        }),
                    style: "cancel",
                },
                { text: "Закрыть", onPress: () => {}, style: "cancel" },
            ],
            { cancelable: false },
        );
    };

    const showNotCanDelivered = useCallback(
        (
            partner: Record<any, any>,
            address: string,
            onClickShowPartners: () => void,
            onClickEditAddress: () => void,
        ) => {
            Alert.alert(
                `Извините, но ресторан "${partner.name}" по адресу "${address}" не доставляет`,
                "",
                [
                    {
                        text: "Посмотреть меню",
                        onPress: async () => {
                            if (typeof onClickShowPartners === "function") {
                                onClickShowPartners();
                            }
                            //navigation.navigate(links.abs.partners);
                            //window.location.href = links.abs.partners
                        },
                        style: "cancel",
                    },
                    {
                        text: "Изменить адрес доставки",
                        onPress: async () => {
                            if (typeof onClickEditAddress === "function") {
                                onClickEditAddress();
                            }
                            /*navigation.navigate(links.abs.map, {
                                return_url: combineLink(
                                    links.abs.partners,
                                    partner.alias,
                                ),
                            })*/
                        },
                        style: "cancel",
                    },
                ],
                { cancelable: false },
            );
        },
        [],
    );

    return {
        showCleanCartMessage,
        showDeliveryNotAvailableMessage,
        isChangedPartnerId,
        showChoiceOptionsMessage,
        showAuthorizationMessage,
        showNotEnoughPointsMessage,
        useReplacePointsDishMesssage,
        showMinSumForPoint,
        showNotAdress,
        showNotCanDelivered,
    };
};

export default useMessage;
