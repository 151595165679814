import * as t from "./types";
import { menuService } from "../../../services";

export const sync = (id, config) => async (dispatch) => {
    dispatch({ type: t.FETCH_MENU_START });

    try {
        const response = await menuService.fetchMenu(id, config);
        dispatch(setMenu(response));
        return response;
    } catch (error) {
        dispatch({ type: t.FETCH_MENU_FAILURE, payload: error });
    }
};

export const syncMiniSite = (config) => async (dispatch) => {
    dispatch({ type: t.FETCH_MENU_START });

    try {
        const response = await menuService.fetchMenuMiniSite(config);
        dispatch(setMenu(response));
        return response;
    } catch (error) {
        dispatch({ type: t.FETCH_MENU_FAILURE, payload: error });
    }
};

export const setMenu = (menu) => async (dispatch) => {
    dispatch({ type: t.FETCH_MENU_SUCCESS, payload: menu });
};

export const reset = () => async (dispatch) => {
    dispatch({ type: t.CLEAR_MENU_START });
};

export const shopMenuSync =
    (partnerId, menuId) => async (dispatch, getState) => {
        dispatch({ type: t.FETCH_MENU_START });

        try {
            const response = await menuService.fetchShopMenu(partnerId, menuId);

            dispatch(setShopMenu(response));
            return response;
        } catch (error) {
            return dispatch({ type: t.FETCH_MENU_FAILURE, payload: error });
        }
    };

export const shopMenuSyncMiniSite = (menuId) => async (dispatch, getState) => {
    dispatch({ type: t.FETCH_MENU_START });

    try {
        const response = await menuService.fetchShopMenuMiniSite("", menuId);

        dispatch(setShopMenu(response));
        return response;
    } catch (error) {
        return dispatch({ type: t.FETCH_MENU_FAILURE, payload: error });
    }
};

export const setShopMenu = (menu) => async (dispatch) => {
    dispatch({ type: t.FETCH_MENU_SUCCESS, payload: menu });
};
