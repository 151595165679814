import * as t from './types';
import {partnerService} from '../../../services';

export const sync = (params?: object, config?) => async (dispatch): Promise<any> => {
    dispatch({ type: t.FETCH_PARTNERS_NEW_LIST_START });
    const data = await fetchPartners(params, config)
    dispatch(setPartners(data))
}

export const fetchPartners = async (params: object, config?) => {

    return await partnerService.fetchPartners(params, config)
}

export const setPartners = (data) => ({ type: t.FETCH_PARTNERS_NEW_LIST_SUCCESS, payload: data });

