import * as t from '../actions/types';

export const initialState = {
    useKassa: null,
    useBonus: null,
    haveDiscount: null,
    //isEnd: false,
    //offset: 0,
    //index: 0,
};

const Reducer = (state =  initialState, { type, payload }) => {
    switch (type) {
        case t.CHANGE_FILTERS_SUCCESS:
            return {
                ...state,
                ...payload,
            };
        case t.RESET_FILTERS_SUCCESS:
            return {
                ...initialState,
            };
        default:
            return state
    }
}

export default Reducer;
