import * as t from '../actions/types';

export const initialState = {
    list: [],
    isLoading: true,
    error: null,
    currentSpecialization: null,
    currentDirection: 1,
    activeId: 'direction_1',
};
export type directionsState = typeof initialState;

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case t.SET_DIRECTIONS:
            return {
                ...initialState,
                list: payload,
                currentSpecialization: state.currentSpecialization,
                currentDirection: state.currentDirection,
                isLoading: false
            };
        case t.SET_SPECIALIZATION:
            return {
                ...initialState,
                list: state.list,
                currentSpecialization: payload.id,
                currentDirection: null,
                activeId: payload.uniqId,
                isLoading: false
            };
        case t.SET_DIRECTION:
            return {
                ...initialState,
                list: state.list,
                currentDirection: payload.id,
                currentSpecialization: null,
                activeId: payload.uniqId,
                isLoading: false
            };

        case t.RESET:
            return {
                ...initialState,
                list: state.list,
            };

        case t.FETCH_DIRECTIONS_START:
            return {
                ...state,
                isLoading: true
            };
        case t.FETCH_DIRECTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case t.FETCH_DIRECTIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};
