import request from "helper/request";
import combineLink from "helper/combineLink";
import api from "helper/api";

class AuthService {
    requestAuth = async (phone: string, code: string) => {
        let options = {
            setLoading: false,
            withLoader: true,
            json: true,
            body: {
                code: code,
                phone: phone.replace(/[^0-9]/g, ''),
            },
        }

        return request.post(combineLink(api.requestAuth), options)
            .then((data) => {

                return data
            })
    };

    requestCode = async (phone: string) => {
        let options = {
            setLoading: false,
            withLoader: true,
            json: true,
            body: {
                phone: phone.replace(/[^0-9]/g, ''),
            }
        }


        //return new Promise((resolve, reject) => resolve(true))

        return request.post(combineLink(api.requestCode), options)
            .then((data) => {

                return data
            })
    };

    requestCodeWithCaptcha = async (phone: string, token) => {
        let options = {
            setLoading: false,
            withLoader: true,
            json: true,
            body: {
                phone: phone.replace(/[^0-9]/g, ''),
                token,
            }
        }


        //return new Promise((resolve, reject) => resolve(true))

        return request.post(combineLink(api.requestCodeWithCaptcha), options)
            .then((data) => {

                return data
            })
    };
}

const authService = new AuthService();
export default authService