import * as t from '../actions/types'

export const initialState = {
    data: [],
    isLoading: false,
    error: null,
    user: {}
};

const Reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case `FETCH_MY_ORDERS_START`:
            return {
                ...state,
                isLoading: true,
            };
        case `FETCH_MY_ORDERS_SUCCESS`:
            return {
                ...state,
                isLoading: false,
                data: [...state.data, ...payload],
            };
        case `FETCH_MY_ORDERS_FAILURE`:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };

        case t.SET_MY_ORDER_SUCCESS:
            const orders = [...state.data]?.map(order => order.id === payload.id ? payload : order);

            return {
                ...state,
                isLoading: false,
                data: [...orders],
            };
        default:
            return state
    }
}

export default Reducer
