import * as t from '../actions/types';

export const initialState = {
    data: {
        token: null as null | string,
        deviceId: null as null | string,
    },
    isLoading: false,
    error: null,
};

export type authState = typeof initialState;

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case t.AUTH_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case t.AUTH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    token: payload.token,
                },
            };
        case t.AUTH_LOG_OUT:
            return {
                ...state,
                isLoading: false,
                data: initialState,
            };
        case t.AUTH_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case t.REMOVE_TOKEN:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    token: null,
                },
            };
        case t.REQUEST_CODE_START:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case t.REQUEST_CODE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case t.SET_DEVICE_ID:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    deviceId: payload,
                },
            };
        default:
            return state;
    }
};

export default reducer;
