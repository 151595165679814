import * as t from '../actions/types';

export const initialState = {
    data: {
        latitude: null,
        longitude: null,
        address: null,
        addressObj: {
            city: null,
        },
    },
    isLoading: false,
    error: null,
    hasDelivery: false,
    deeplink: {},
    addresses: [],
    selectedAddressId: null,
};

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case t.ADD_MAP_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: { ...state.data, ...payload },
            };
        case t.FETCH_PARTNERS:
            return {
                ...state,
                hasDelivery: !!payload,
            };
        case t.GET_DEEPLINK:
            return {
                ...state,
                deeplink: { payload },
            };
        case t.SET_SELECTED_ADDRESS_ID:
            return {
                ...state,
                selectedAddressId: payload,
            };
        case 'persist/REHYDRATE':
            const returnDate = state.data.addressObj.id ? state.data : payload?.map ? payload.map.data : state.data


            /*return {
                ...state,
                isLoading: false,
                data: { ...state.data, ...payload },
            };*/
        default:
            return state;
    }
};

export default Reducer
