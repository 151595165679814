import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
//import IProps from "./IProps";

class Alert {
    private options = {
        title: "Title",
        message: "Message",
        buttons: [
            {
                label: "Yes",
                onClick: () => alert("Click Yes"),
            },
            {
                label: "No",
                onClick: () => alert("Click No"),
            },
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
    };

    public alert = (head = "", message = "", buttons = [], params) => {
        this.options.title = head;
        this.options.message = message;
        this.options.buttons = buttons.map((item) => ({
            label: item.text,
            onClick: item.onPress,
        }));
        if (params?.cancelable) {
            this.options.closeOnEscape = false;
            this.options.closeOnClickOutside = false;
        }

        confirmAlert(this.options);
    };
}

export default new Alert();
