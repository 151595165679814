import * as t from '../actions/types';

export const initialState = {
    data: {
        list: []
    },
    isLoading: true,
    error: null,
};

//TODO вынести в хелпер
const shuffle = (arr = []) => {

    return arr?.sort(() => Math.round(Math.random() * 100) - 50);
}

const Reducer = (state =  initialState, { type, payload }) => {
    switch (type) {
        case t.FETCH_PARTNERS_NEW_LIST_START:
            return {
                ...initialState,
                isLoading: true,
            };
        case t.FETCH_PARTNERS_NEW_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...payload,
                    list: shuffle(payload?.list.filter(item => !item.isClosed)),
                }
            };
        case t.FETCH_PARTNERS_NEW_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        default:
            return state
    }
}

export default Reducer;
