import { Partner } from 'modules/Partner/interfaces/Partner';
import { CartItemIN } from 'CartItemIN.ts';
import { Gift } from 'modules/Cart/interfaces/Gift';
import * as t from '../actions/types';

const initialState = {
    data: {
        total: null as number | null,
        totalClean: null as number | null,
        deliveryCost: null as number | null,
        boxCost: null as number | null,
        boxQuantity: null as number | null,
        drinks: [],
        gift: [] as Gift[],
        promo: [],
        bonuses: null as number | null,
        cartItems: [] as CartItemIN[],
        partner: null as Partner | null,
    },
    isLoading: true,
    isAddLoading: false,
    error: null,
};

export type cartState = typeof initialState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case t.FETCH_CART_START:
            return {
                ...initialState,
                isLoading: true,
                error: null,
            };
        case t.FETCH_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case t.FETCH_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case t.ADD_TO_CART_START:
            return {
                ...state,
                isLoading: true,
                isAddLoading: true,
                error: null,
            };
        case t.ADD_TO_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAddLoading: false,
                data: payload.cart,
            };
        case t.ADD_TO_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
                isAddLoading: false,
                error: payload,
            };
        case t.UPDATE_CART_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case t.UPDATE_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload.cart,
            };
        case t.UPDATE_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case t.REMOVE_CART_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case t.REMOVE_CART_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case t.REMOVE_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case t.CLEAR_CART_START:
            return {
                ...state,
                isLoading: true,
            };
        case t.CLEAR_CART_SUCCESS:
            return {
                ...initialState,
            };
        case t.CLEAR_CART_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };

        default:
            return state;
    }
};
