import * as t from '../actions/types';

export const initialState = {
    data: [],
    isLoading: false,
    error: null,
    user: {}
};

const Reducer =  (state = initialState, {type, payload}) => {
    switch (type) {
        case t.FETCH_USER_DATA:
            return {
                ...state,
                user: payload
            };
        case t.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                data: payload,
            };
        case t.CREATE_ORDER_FAILURE:
            return {
                ...initialState,
                error: payload,
            };
        default:
            return state
    }
}

export default Reducer
