import * as t from '../actions/types';
import { Menu } from 'modules/Partner/interfaces/Menu';

export const initialState = {
    data: {
        main: [] as Menu[],
        goodsForPoints: []
    },
    isLoading: true,
    error: null,
    lastSubMenu: {} as Menu
};
export type menuState = typeof initialState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case t.FETCH_MENU_START:
            return {
                ...initialState,
                isLoading: true,
            };
        case t.FETCH_MENU_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case t.FETCH_MENU_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case t.CLEAR_MENU_START:
            return {
                ...initialState,
                //isLoading: false,
            };
        case t.SET_LAST_SUBMENU:
            return {
                ...state,
                lastSubMenu: payload,
            };
        default:
            return state
    }
}