import * as t from '../actions/types';

export const initialState = {
    data: [],
    isLoading: true,
    error: null,
};



const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case t.FETCH_BONUS_START:
            return {
                ...state,
                isLoading: true,
            };
        case t.FETCH_BONUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case t.FETCH_BONUS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        default:
            return state;
    }
};

export default reducer;
