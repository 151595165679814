
export const initialState = {
    data: [],
    isLoading: false,
    error: null,
};



const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        /*case t.FETCH_PROMOTIONS_START:
            return {
                ...state,
                isLoading: true,
            };
        case t.FETCH_PROMOTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case t.FETCH_PROMOTIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };*/
        default:
            return state;
    }
};

export default reducer;
