import {useSelector} from "react-redux";
import { useAppDispatch, RootState } from "store";
import {sync, clear, partnerSet, syncMiniSite} from "modules/Partner/store/partner/actions";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";
import domainHelper from "helper/domainHelper";

const usePartnerOpen = (props?) => {
    const partner = useSelector((state: RootState) => state.partner);
    const { workDays, workTime = [] } = partner.data;
    const workDaysArray = workDays ? workDays.split(" ") : [];
    const [startWorkingDay, endWorkingDay] = workTime;

    const openWorkHours = `с ${startWorkingDay} до ${endWorkingDay}`;
    const openWorkDays =
        `${workDaysArray[0]}-${workDaysArray[workDaysArray.length - 1]}`;
    const dispatch = useAppDispatch();
    const isMiniSite = useMiniSiteDetect()

    const partnerSync = (partnerId?) => {

        if(isMiniSite){
            partnerSyncMiniSite()
        } else {
            if(partnerId){
                dispatch(sync(partnerId));
            }
        }
    }

    const partnerSyncMiniSite = () => {
        const baseApi = location.href;
        //const { getMainDomain } = domainHelper();

        //const baseApi = getMainDomain(hostname);
        dispatch(syncMiniSite());
    }

    const onPartnerSet = (partner) => {
        dispatch(partnerSet(partner))
    }

    const partnerClear = () => {
        dispatch(clear())
    }

    const checkBlockedPartner = (partner = {}) => {
        return partner.status === 0
    }

    return {partner, partnerSync, partnerClear, openWorkHours, openWorkDays, onPartnerSet, partnerSyncMiniSite, checkBlockedPartner}
}

export default usePartnerOpen
