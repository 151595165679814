import {sync, loadMore} from "modules/Partner/store/partners/actions";

import {useSelector} from "react-redux";
import { useAppDispatch, RootState } from "store";

const usePartner = (props?) => {
    const partners = useSelector((state: RootState) => state.partners);
    const dispatch = useAppDispatch();

    const partnersSync = (params?: object,directionOrSpecialization, config?) => {

        //console.log('partnersSync')
        const query = {
            ...params,
            ...(directionOrSpecialization
                ? { specializationAlias: directionOrSpecialization }
                : {}),
        }
        dispatch(sync(query, config))
    }

    const partnersLoadMore = (params?: object, config?) => {

        //console.log('config', config)

        dispatch(loadMore(params, config))
    }


    /*useEffect(() => {
        if(!props?.notFetch && !partners?.data?.list?.length || isHasCoords){
            partnersSync()
        }
    },[])*/

    return {partners, partnersSync, partnersLoadMore}
}

export default usePartner