import * as t from '../actions/types';
import { Partner } from '../../../interfaces/Partner';

export const initialState = {
    data: {} as Partner,
    isLoading: true,
    error: null,
};

export type partnerState = typeof initialState;

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case t.CLEAR_PARTNER_SUCCESS:
            return {
                ...initialState,
            };
        case t.FETCH_PARTNER_START:
            return {
                ...initialState,
                isLoading: true,
            };
        case t.FETCH_PARTNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        case t.FETCH_PARTNER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        default:
            return state
    }
}
