import { Dispatch } from 'redux';
import { mapService } from '../../../services';
import * as t from './types';

export const fetchCities = (data) => async (dispatch: Dispatch) => {
    dispatch({ type: t.FETCH_CITIES_START });

    try {
        const response = await mapService.fetchCities(data);
        dispatch({ type: t.FETCH_CITIES_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: t.FETCH_CITIES_FAILURE, payload: error?.response });
    }
};

export const fetchCitiesMiniSite = (data) => async (dispatch: Dispatch) => {
    dispatch({ type: t.FETCH_CITIES_START });

    try {
        const response = await mapService.fetchCitiesMiniSite(data);
        dispatch({ type: t.FETCH_CITIES_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: t.FETCH_CITIES_FAILURE, payload: error?.response });
    }
};
