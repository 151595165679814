import mapValues from "lodash/mapValues";

//const baseApi: string = process.env.NEXT_PUBLIC_API_URL;
const baseApi: string =
    process.env.NODE_ENV === "development"
        ? "https://izum05.ru/api/v1/"
        : "/api/v1/";
//const baseApi = "/api/v1/";
//const baseApi: string = 'http://izum05.ru/api/v1/'

const createLinks = (links: Record<string, string>): Record<string, string> =>
    mapValues(links, (link: string) => `${baseApi}${link}`);

const apiMiniSite = createLinks({
    partner: "partner",
    productSearch: "partner/productSearch",
    menu: "menu",
    cart: "cart",
    order: "order",
    city: "city",
});

export default apiMiniSite;
