import * as t from '../actions/types';

export const initialState = {
    data: [],
    isLoading: false,
    error: null,
};

const Reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case t.FETCH_CITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload,
            };
        default:
            return state;
    }
};

export default Reducer