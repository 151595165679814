import {useEffect} from "react";
import {changeFilter} from "modules/Partner/store/filters/actions";
import {parseLocationSearch} from "helper";
import {sync} from "modules/Cart/store/cart/actions";
import {setDeviceId} from "modules/User/store/auth/actions";
import {getProfile} from "modules/User/store/profile/actions";
import {fetchCitiesMiniSite} from "modules/Map/store/cities/actions";
import {addressSync} from "modules/Map/store/address/actions";
import {RootState, useAppDispatch} from "store";
import {useSelector} from "react-redux";
import 'react-notifications/lib/notifications.css';
import Head from 'next/head'
//import * as t from "modules/User/store/auth/actions/types";


export default function AppLayout({ children }) {
    const map = useSelector((state:RootState) => state.map)
    const auth = useSelector((state:RootState) => state.auth)
    const dispatch = useAppDispatch();


    useEffect(() => {
        //window.isMobileDetect = isMobile
        dispatch(setDeviceId())


        let latitude = map?.data?.latitude;
        let longitude = map?.data?.longitude;
        const isAuth = Boolean(auth?.data?.token)

        /*if(!isAuth){
            dispatch({
                type: t.AUTH_SUCCESS,
                payload: { token: "b365dc7d0632661aa6b030c9ab747e6d" },
            });
        }*/

        if(latitude && longitude){
            //let mapData = {...map?.data}
            //delete mapData.cities;
            //Cookies.set('mapData', JSON.stringify(mapData))
        }

        if(isAuth){
            dispatch(getProfile())
            dispatch(addressSync())
        }

        let filterQuery = parseLocationSearch(location.search)
        dispatch(changeFilter(filterQuery))
        dispatch(fetchCitiesMiniSite())
        dispatch(sync())

    }, [])




    return (
        <>
            <Head>
                <title>
                    Доставка еды: заказ вкусной еды на дом | Edostav.ru
                </title>
                <meta
                    name="keywords"
                    content="доставка еды, заказ, купить, отзывы, рейтинги, Edostav.ru"
                />
                <meta
                    name="description"
                    content="Заказ и доставка еды из лучших ресторанов. Закажите вкусную еду на дом, участвуйте в акциях и бонусных программах. Отзывы и рейтинги на сайте dostavka05.ru."
                />
            </Head>
            {children}
        </>
    );
}
