import * as t from './types';

export const sync = (product) => async (dispatch, getState) => {
    const partner = getState().partner.data;

    return dispatch({ type: t.OPEN_PRODUCT_START, payload: { ...product, partner } });
};

export const clear = () => async (dispatch) => {
    return dispatch({ type: t.CLEAR_PRODUCT_START });
};
