import * as t from './types';
import { Dispatch } from 'redux';
import {addressService} from "modules/Map/services";

export const addressSync = (config) => async (dispatch: Dispatch) => {

    dispatch({ type: t.FETCH_ADDRESS_START });

    try {
        const data = await addressService.fetchAddress(config);
        let payload = data;
        return dispatch({ type: t.FETCH_ADDRESS_SUCCESS, payload });
    } catch (error) {
        //console.log(error)
        dispatch({ type: t.FETCH_ADDRESS_FAILURE, payload: error?.response });
        return  error?.response
    }
};

export const createAddress = (values) => async (dispatch: Dispatch) => {

    dispatch({ type: t.CREATE_ADDRESS_START });

    try {
        const data  = await addressService.createAddress(values);
        let payload = data;
        return dispatch({ type: t.CREATE_ADDRESS_START, payload });
    } catch (error) {
        //console.log(error)
        dispatch({ type: t.CREATE_ADDRESS_FAILURE, payload: error?.response });
        return  error?.response
    }
};

export const updateAddress = (values, addressId) => async (dispatch: Dispatch) => {
    dispatch({ type: t.UPDATE_ADDRESS_START });

    try {
        const data = await addressService.updateAddress(values, addressId);
        let payload = data;
        return dispatch({ type: t.UPDATE_ADDRESS_START, payload });
    } catch (error) {
        //console.log(error)
        dispatch({ type: t.UPDATE_ADDRESS_FAILURE, payload: error?.response });
        return  error?.response
    }
};

export const deleteAddress = (id) => async (dispatch: Dispatch) => {

    dispatch({ type: t.DELETE_ADDRESS_START });

    try {
        const data  = await addressService.deleteAddress(id);
        let payload = data;
        return dispatch({ type: t.DELETE_ADDRESS_START, payload });
    } catch (error) {
        //console.log(error)
        dispatch({ type: t.DELETE_ADDRESS_FAILURE, payload: error?.response });
        return  error?.response
    }
};

