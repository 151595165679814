import {sync} from "modules/Partner/store/partnersNew/actions";

import {useSelector} from "react-redux";
import { useAppDispatch, RootState } from "store";



const usePartnerNew = (props?) => {
    const partnersNew = useSelector((state: RootState) => state.partnersNew);
    const dispatch = useAppDispatch();

    const partnersNewSync = (params?: object) => {

        dispatch(sync({isNew:1, ...params}))
    }



    return {partnersNew, partnersNewSync}
}

export default usePartnerNew