var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"hJJan0XIZj_aKOBDGCv8u"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

/*import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if(process.env.NEXT_PUBLIC_API_URL === 'https://www.edostav.ru/api/v3/') {
    Sentry.init({
        dsn: SENTRY_DSN || 'https://f28f87435282434ba861baf89e3d7248@o4504094439899136.ingest.sentry.io/4504094442717184',
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [
            new Sentry.Replay({
                maskAllText: false,
            }),
        ],
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}*/



