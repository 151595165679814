import * as t from './types';
import {partnerService} from '../../../services';

export const sync = (params?: object, config?) => async (dispatch): Promise<any> => {
    dispatch({ type: t.FETCH_PARTNERS_LIST_START });
    const data = await fetchPartners(params, config)



    dispatch(setPartners(data))
}

export const loadMore = (params?: object, config?) => async (dispatch): Promise<any> => {
    dispatch({ type: t.FETCH_PARTNERS_MORE_LIST_START });
    const data = await fetchPartners(params, config)



    dispatch(setMorePartners(data))
}

export const fetchPartners = async (params: object, config?: object) => {

    return await partnerService.fetchPartners(params, config)
}


export const setPartners = (data) => ({ type: t.FETCH_PARTNERS_LIST_SUCCESS, payload: data });

export const setMorePartners = (data) => ({ type: t.FETCH_PARTNERS_MORE_LIST_SUCCESS, payload: data });

