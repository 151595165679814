import * as t from './types';
import { partnerService } from '../../../services';

export const sync = (id, config?) => async (dispatch) => {
    dispatch({ type: t.FETCH_PARTNER_START });

    try {
        const response = await partnerService.fetchPartner(id, config);

        dispatch(partnerSet(response))

        return response;
    } catch (error) {
        dispatch({ type: t.FETCH_PARTNER_FAILURE, payload: error });
    }
};

export const syncMiniSite = (baseApi?, config?) => async (dispatch) => {
    dispatch({ type: t.FETCH_PARTNER_START });

    try {
        const response = await partnerService.fetchPartnerMiniSite(
            baseApi,
            config,
        );
        dispatch(partnerSet(response));

        return response;
    } catch (error) {
        dispatch({ type: t.FETCH_PARTNER_FAILURE, payload: error });
    }
};

export const partnerSet = (partner) => async (dispatch) => {

    dispatch({ type: t.FETCH_PARTNER_SUCCESS, payload: partner });
};

export const clear = () => async (dispatch) => {
    dispatch({ type: t.CLEAR_PARTNER_SUCCESS });
};
