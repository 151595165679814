export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const CURRENT_USER = 'AUTH/CURRENT_USER';

export const REQUEST_CODE_START = 'REQUEST_CODE_START';
export const REQUEST_CODE_SUCCESS = 'REQUEST_CODE_SUCCESS';

export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const DESTROY_SESSION = 'DESTROY_SESSION';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
