import { useCallback } from 'react'
import {useSelector} from "react-redux";
import { useAppDispatch } from "store";
import {
    restaurantsCategoriesListSelector,
    restaurantsCategoriesSelector
} from 'modules/Partner/selectors/restaurantsCategoriesSelectors';
import { getDirections } from 'modules/Partner/store/restaurantsCategories/actions';
import { useFilter } from 'modules/Partner/hooks';
import {Direction} from "../../interfaces/Direction";
import {Specialization} from "../../interfaces/Specialization";
import links from "helper/links";
import {usePartner} from "../index";
import navRoute from "helper/navRoute";

const useDirection = () => {
    const dispatch = useAppDispatch();
    const directions = useSelector(restaurantsCategoriesListSelector);
    const isLoadingDirections = useSelector(restaurantsCategoriesSelector)?.isLoading;
    const {onChangeFilter, getActiveFilter, filters} = useFilter();
    const {partnersSync} = usePartner()
    const navigation = navRoute()
    const isDirection = (item: Specialization | Direction) => 'position' in item;



    const onFetchDerections = () => {
        dispatch(getDirections());
    }

    const onDirectionPress = useCallback(async (data) => {
        const directionData = {
            [isDirection(data) ? 'direction' : 'specialization'] : data.alias,
            [!isDirection(data) ? 'direction' : 'specialization'] : null
        }


        await onChangeFilter(directionData)

        const filterQuery = await getActiveFilter({
            ...filters,
            ...directionData
        })

        //console.log('filterQuery', filterQuery)
        navigation.navigate(links.abs.partners, filterQuery)
        partnersSync(filterQuery)

    }, [directions, filters]);


    //const hasDirections = directions?.length > 0;

    /*const getActive = useCallback(
        (data) => {
            return isDirection(data)
                ? parseInt(filters.direction) === data.id
                : parseInt(filters.specialization) === data.id;

    }, [filters])*/

    const getActive = useCallback(
        (data) => {

            const urlAlias = navigation.helpers.checkTemplateHasCoords() ?
                navigation.query.alias : navigation.query.categoryAlias


            if((navigation.query.categoryAlias === 'all' || navigation.query.alias === 'all') && data.alias === 'restorany') {
                return true
            }

            return (navigation.query.categoryAlias === data.alias) || urlAlias === data.alias;

        }, [navigation.query.alias, navigation.query.categoryAlias, navigation.asPath])

    const getHtmlExtraForDirection = useCallback(
        () => {
            const seo = directions.find(item =>
                (navigation.query.alias === item.alias) ||
                (navigation.query.categoryAlias === item.alias))?.htmlExtra

            //console.log('seoseo', seo, directions, navigation.query.categoryAlias)

            return seo

        }, [navigation.query.categoryAlias, navigation.query.alias,  directions])

    const getActiveNameDirection = useCallback(
        () => {
            if((navigation.query.categoryAlias === 'all' || navigation.query.alias === 'all')) {
                return 'Рестораны'
            }
            const name = directions.find(item =>
                (navigation.query.categoryAlias === item.alias) ||
                (navigation.query.alias === item.alias))?.name

            return name
        }, [navigation.query.categoryAlias, navigation.query.alias, directions])


    return {
        directions,
        onDirectionPress,
        getActive,
        onFetchDerections,
        getHtmlExtraForDirection,
        getActiveNameDirection,
        isLoadingDirections
    }
}

export default useDirection
