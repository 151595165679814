//import { showMessage } from 'react-native-flash-message';
import { Dispatch } from 'redux';
import { CartItemIN } from 'CartItemIN.ts';
import { cartService, cartMinSiteService } from 'modules/Cart/services';
import * as t from './types';
import defineAndGetModule from "helper/defineAndGetModule";

type ServiceType = typeof cartService | typeof cartMinSiteService;

const service: ServiceType = defineAndGetModule(
    cartService,
    cartMinSiteService,
);

export const addToCart = (params: CartItemIN) => async (dispatch: Dispatch, getState) => {
    dispatch({ type: t.ADD_TO_CART_START });

    const cartItems = getState().cart.data.cartItems;
    const menu = getState().menu.data?.main;
    const paramsModifiersArraySorted = params.modifiers
        .slice()
        .split(',')
        .sort();

    const product =
        !!cartItems &&
        cartItems?.find(
            (item: { productId: any, type: any, modifiers: any }) =>
                item.productId === params.productId &&
                item.type === params.type &&
                item.modifiers.length === params.modifiers.length &&
                item.modifiers
                    .slice()
                    .sort()
                    .every(function(value, index) {
                        return value === paramsModifiersArraySorted[index];
                    })
        );

    try {
        const response = await service.addToCart(params, product);


        return dispatch({ type: t.ADD_TO_CART_SUCCESS, payload: response });
    } catch (error) {
        const err = error?.message ? error.message : error
        dispatch({ type: t.ADD_TO_CART_FAILURE, payload: err })
        return {error: true, message: err};
    }
};

export const updateItemInCart = (id: number, data: CartItemIN) => async (dispatch: Dispatch, getState) => {
    dispatch({ type: t.UPDATE_CART_START });
    //const mapData = getState().map.data;
    try {
        const response = await service.updateCartItem(id, data);
        return dispatch({ type: t.UPDATE_CART_SUCCESS, payload: response });
    } catch (error) {
        const message = error?.response?.data.message ?? 'Ошибка при обновлении корзины';
        /*showMessage({
            message,
            type: 'danger',
        });*/


        return dispatch({ type: t.UPDATE_CART_FAILURE, payload: error?.response });
    }
};

export const sync = (params?: any) => async (dispatch: Dispatch) => {
    dispatch({ type: t.FETCH_CART_START });
    //const mapData = getState().map.data;

    try {
        const response = await service.fetchCart(params);
        return dispatch({ type: t.FETCH_CART_SUCCESS, payload: response });
    } catch (error) {
        return dispatch({ type: t.FETCH_CART_FAILURE, payload: error?.response });
    }
};

export const removeCartItem = (id: number, zoneId: number) => async (dispatch: Dispatch) => {
    dispatch({ type: t.REMOVE_CART_START });

    try {
        const response = await service.removeCartItem(id, zoneId);
        dispatch({ type: t.REMOVE_CART_SUCCESS, payload: response });
    } catch (error) {
        const message = error.response?.data.message ?? 'Ошибка при удалении товара';
        /*showMessage({
            message,
            type: 'danger',
        });*/
        dispatch({ type: t.REMOVE_CART_FAILURE, payload: error?.response });
    }
};

export const clearCart = () => async (dispatch: Dispatch) => {

    dispatch({ type: t.CLEAR_CART_START });
    try {
        const response = await service.clearCart();
        dispatch({ type: t.CLEAR_CART_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: t.CLEAR_CART_FAILURE, payload: error?.response });
    }
};
