import React from "react";
import {MiniSiteDetectContext} from 'common/context/MiniSiteDetect';


export default function Layout({ children }) {

    return (
        <MiniSiteDetectContext.Provider value={true}>
            {children}
        </MiniSiteDetectContext.Provider>
    )
}