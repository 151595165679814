import * as t from '../actions/types';

export const initialState = {
    data: {
        list: []
    },
    isFetching: true,
    error: null,
};

const Reducer = (state =  initialState, { type, payload }) => {
    switch (type) {
        case t.FETCH_PARTNERS_LIST_START:
            return {
                ...initialState,
                isFetching: true,
            };
        case t.FETCH_PARTNERS_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: payload,
            };
        case t.FETCH_PARTNERS_MORE_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                data: {list: [...state.data.list, ...payload.list]}
            };
        case t.FETCH_PARTNERS_LIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: payload,
            };
        default:
            return state
    }
}

export default Reducer;
