import * as t from './types';
import {profileService} from '../../../services';
import { Dispatch } from 'redux';
import { removeAuthToken } from 'modules/User/store/auth/actions';

export const setProfile = (data) => {
    return { type: t.SET_CURRENT_USER, payload: data };
};

export const setOrdersWithoutReviews = (count) => ({
    type: t.ADD_ORDERS_WITHOUT_REVIEWS,
    payload: count,
});
export const decreaseOrdersWithoutReviews = () => ({ type: t.DECREASE_ORDERS_WITHOUT_REVIEW });

export const getProfile = () => async (dispatch: Dispatch) => {
    dispatch({ type: t.SHOW_LOADER });


    try {
        const response = await profileService.getProfile();


        dispatch(setProfile(response));
    } catch (error){

        if(error?.code === 401){
            dispatch(removeAuthToken())
        }
        //alert('Произошла ошибка с получением данных')
    } finally {
        dispatch({ type: t.HIDE_LOADER });
    }
};

export const updateProfile = (formValues) => async (dispatch: Dispatch, getState) => {
    dispatch({ type: t.SHOW_LOADER });

    try {
        const response = await profileService.updateProfile(null, formValues);
        //dispatch(setProfile(response.data));
        alert('Данные успешно сохранены')
        dispatch(getProfile())

    } catch (error) {
        //alert( error.response?.data.message && 'Произошла ошибка с отправкой данных')
    } finally {
        dispatch({ type: t.HIDE_LOADER });
    }
};




