import request from "helper/request";
import combineLink from "helper/combineLink";
import api from "helper/api";

class ProfileService {
    getProfile = async (token: string | null) => {
        let options = {
            setLoading: false,
            withLoader: true,
        }

        return request.get(combineLink(api.profile), options).then(data => data)
    };

    updateProfile = async (token: string | null, formValues) => {

        const options = {
            setLoading: false,
            withLoader: false,
            body: formValues,
        };

        return request.put(combineLink(api.profile), options).then(data => data)
    };
}

const profileService = new ProfileService();
export default profileService