import {useEffect} from 'react'
import { wrapper } from 'store'
import 'public/styles.css'
import AppLayout from "common/layouts/AppLayout";
import MiniSiteLayout from "common/layouts/MiniSiteLayout";
import {getDomain} from "store";
import {getCookie, removeCookies, setCookies} from "cookies-next";
import {usePartnerOpen} from "modules/Partner/hooks";



const WrappedApp = ({ Component, pageProps }) => {
    const {partnerSyncMiniSite} = usePartnerOpen()


    useEffect(() => {
        //const {removeCity} =  cityHelper();
        //let hostnameFromUrl = req.headers.host
        const COOKIE_VERSION = process.env.NEXT_PUBLIC_COOKIE_VERSION
        //const domainWithoutPort = port ? hostnameFromUrl.replace(":" + port, '') : hostnameFromUrl
        //const domainWithoutPort = hostnameFromUrl
        //const domainForCookie = removeCity(domainWithoutPort)

        const domainForCookie = getDomain()

        const COOKIE_VERSION_SAVED = getCookie('cookie_version', {domain: domainForCookie})

        if(COOKIE_VERSION !== COOKIE_VERSION_SAVED){
            //removeCookies('map', { domain: domainForCookie});
            //removeCookies('auth', { domain: domainForCookie});
            //setCookies('cookie_version', COOKIE_VERSION, { domain: domainForCookie})
        }
    }, [])

    useEffect(() => {
        partnerSyncMiniSite()
    }, [])

    if (typeof window === "undefined") {
        return (
            //<Provider store={store}>
                <Component {...pageProps} />
            //</Provider>
        );
    }


    return (
        <AppLayout>
            <MiniSiteLayout>
                <Component {...pageProps} />
            </MiniSiteLayout>
        </AppLayout>
    )
}



export default wrapper.withRedux(WrappedApp)
