import combineLink from "helper/combineLink";
import request from "helper/request";
import api from "helper/api";

class DirectionsService {
    getDirection = async (config) => {
        //console.log('fetchPartners')
        let options = {
            setLoading: false,
            withLoader: true,
            json: true,
            ...config
            //crossDomain: true,
        }

        return request.get(combineLink(api.direction), options)
            .then((data) => {
                //console.log('datadata', data)

                return data
            })
    };

}

const directionsService = new DirectionsService();
export default directionsService
