import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { fetchPartners, reset as resetPartners } from 'modules/Partner/store/partners/actions';
import { Specialization } from 'modules/Partner/interfaces/Specialization';
import { directionsService } from 'modules/Partner/services';

import * as t from './types';
import { Direction } from 'modules/Partner/interfaces/Direction';

const idDirection = (item: Specialization | Direction) => 'position' in item;

export const setDirections = (list: Specialization[]) => ({
    type: t.SET_DIRECTIONS,
    payload: list,
});

export const reset = () => ({ type: t.RESET });

export const setCurrentDirection = (payload: Specialization) => ({
    type: t.SET_DIRECTION,
    payload,
});

export const setSpecialization = (payload: Specialization) => ({
    type: t.SET_SPECIALIZATION,
    payload,
});

export const setDirection = (item: Specialization) => (dispatch) => {
    //dispatch(resetList());

    if (idDirection(item)) {
        dispatch(setCurrentDirection(item));
    } else {
        dispatch(setSpecialization(item));
    }

    dispatch(fetchPartners());
};

export const getDirections = (config): ThunkAction<void, any, unknown, Action<string>> => async (
    dispatch,
) => {

    dispatch({type: t.FETCH_DIRECTIONS_START})

    try {
        const data  = await directionsService.getDirection(config);
        dispatch({type: t.FETCH_DIRECTIONS_SUCCESS})

        const [firstDirection] = data;
        const specializations: Omit<Specialization, 'uniqId'>[] = firstDirection.specializations.slice(
            0,
            5
        );
        const otherDirections: Omit<Direction, 'uniqId'>[] = data.slice(1);
        const mainDirection: Pick<Direction, 'name' | 'id' | 'position'> = {
            name: 'Все',
            id: 1,
            position: NaN,
            alias: 'restorany'
        };

        const directions: Specialization[] = [mainDirection, ...specializations, ...otherDirections];
        const uniqDirections = directions.map((direction) => ({
            ...direction,
            uniqId: `${idDirection(direction) ? 'direction' : 'specialization'}_${direction.id}`,
        }));

        dispatch(setDirections(uniqDirections));
    } catch (e) {
        dispatch({type: t.FETCH_DIRECTIONS_FAILURE})
    }
};

export const generateDirections = async (config) => {

    try {
        const data  = await directionsService.getDirection(config);

        const [firstDirection] = data;
        const specializations: Omit<Specialization, 'uniqId'>[] = firstDirection.specializations.slice(
            0,
            5
        );
        const otherDirections: Omit<Direction, 'uniqId'>[] = data.slice(1);
        const mainDirection: Pick<Direction, 'name' | 'id' | 'position'> = {
            name: 'Все',
            id: 1,
            position: NaN,
            alias: 'restorany'
        };

        const directions: Specialization[] = [mainDirection, ...specializations, ...otherDirections];
        const uniqDirections = directions.map((direction) => ({
            ...direction,
            uniqId: `${idDirection(direction) ? 'direction' : 'specialization'}_${direction.id}`,
        }));

        return uniqDirections

    } catch (e) {}
};
