import * as t from './types';
import {authService} from '../../../services';
import { Dispatch } from 'redux';
import getDeviceId from 'helper/getDeviceId';
import { load } from 'recaptcha-v3'
import api from "helper/api";

//export const logOutAction = () => ({ type: t.DESTROY_SESSION });
export const logOutAction = () => ({ type: t.AUTH_LOG_OUT });

export const removeAuthToken = () => ({ type: t.REMOVE_TOKEN });

export const requestAuth = (phone: string, code: string): any => async (dispatch: Dispatch) => {
    dispatch({ type: t.AUTH_START });

    try {
        const response = await authService.requestAuth(phone, code);
        //dispatch(setUser(response.data.user));
        dispatch({ type: t.AUTH_SUCCESS, payload: response });

        return response;
    } catch (error) {

        dispatch({ type: t.AUTH_FAILURE, payload: error });

        return error
    }
};

export const requestCode = (phone: string): any => async (dispatch) => {
    dispatch({ type: t.REQUEST_CODE_START });



    try {
        const captcahKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY

        if(captcahKey){ //Todo перенести в хуки
            const recaptcha = await load(process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY, {
                autoHideBadge: true
            })

            console.log('api.requestCodeWithCaptcha', api.requestCodeWithCaptcha)

            const token = await recaptcha.execute('homepage')
            await authService.requestCodeWithCaptcha(phone, token);
        } else {
            await authService.requestCode(phone);
        }

        dispatch({ type: t.REQUEST_CODE_SUCCESS});

        return true;
    } catch (error) {
        dispatch({ type: t.AUTH_FAILURE, payload: error});

        console.log('error', error)

        return error
    }
};

export const logOut = (navigation) => async (dispatch) => {
    await alert(
        'Вы уверены, что хотите выйти из аккаунта?',
        /*'',
        [
            {
                text: 'Да',
                onPress: () => {
                    dispatch(logOutAction());
                    navigation.navigate('Map');
                },
            },
            { text: 'Отмена', onPress: () => {}, style: 'cancel' },
        ],
        { cancelable: false }*/
    );
};

export const setDeviceId = (): any => async (dispatch, getState) => {
    let deviceId = getState().auth?.data?.deviceId;
    if(!deviceId){
        deviceId = getDeviceId(151)
        dispatch({ type: t.SET_DEVICE_ID, payload: deviceId });
    }
};
