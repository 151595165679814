import { usePartnerOpen } from "modules/Partner/hooks";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "store";
import {
    sync,
    reset,
    shopMenuSync,
    setMenu,
    setShopMenu,
    shopMenuSyncMiniSite,
} from "modules/Partner/store/menu/actions";

import * as partnerOpenActions from "modules/Partner/store/productOpen/actions";
import { useMemo } from "react";
import { useMiniSiteDetect } from "common/context/MiniSiteDetect";

const useMenu = () => {
    const menu = useSelector((state: RootState) => state.menu);
    const productOpen = useSelector((state: RootState) => state.productOpen);
    const { partner } = usePartnerOpen();
    const isMiniSite = useMiniSiteDetect();
    const dispatch = useAppDispatch();

    const resolveMenuItems = (menu: any): any => {
        return menu.main;

        /*const isFavotiteGoods = Boolean(
            menu.favoriteGoods && menu.favoriteGoods.length,
        );
        const isGoodsForPoints = Boolean(
            menu.goodsForPoints && menu.goodsForPoints.length,
        );

        if (isFavotiteGoods && isGoodsForPoints) {
            return [
                {
                    id: -1,
                    name: 'Еда за баллы',
                    products: menu.goodsForPoints,
                    subMenu: [],
                },
                { id: -2, name: 'Выгодно', products: menu.favoriteGoods, subMenu: [] },
                ...menu.main,
            ];
        } else if (isFavotiteGoods) {
            return [
                { id: -2, name: 'Выгодно', products: menu.favoriteGoods, subMenu: [] },
                ...menu.main,
            ];
        } else if (isGoodsForPoints) {
            return [
                {
                    id: -1,
                    name: 'Еда за баллы',
                    products: menu.goodsForPoints,
                    subMenu: [],
                },
                ...menu.main,
            ];
        } else {
            return menu.main;
        }*/
    };

    //const idForPointsSection = -1;

    const menuItems = resolveMenuItems(menu?.data);

    const isShopPartner = partner.data?.menuViewType === 1;
    //const isShopPartner = true;

    const getProductPrice = (product) => {
        if (!product) return "";

        return product.isProductForPoint
            ? `${product.pointsPrice} Б`
            : `${product.price} ₽`;
    };

    const menuSync = (id) => {
        dispatch(sync(id));
    };

    const onMenuSet = (menu) => {
        dispatch(setMenu(menu));
    };

    const menuReset = () => {
        dispatch(reset());
    };

    const productSync = async (
        product,
        isProductForPoint,
        cartItemId,
        isGiftItems,
    ) => {
        //const product = findProductById(id)

        //console.log('product', product, menu.data.main)
        dispatch(
            partnerOpenActions.sync({
                ...product,
                isProductForPoint,
                cartItemId,
                isGiftItems,
            }),
        );
    };

    const productReset = (product, isProductForPoint, cartItemId) => {
        //console.log('productReset')
        dispatch(partnerOpenActions.clear());
    };

    const onMenuShopSync = async (partnerId, productId) => {
        return dispatch(shopMenuSyncMiniSite(productId));
    };

    const onMenuShopSet = (menu) => {
        dispatch(setShopMenu(menu));
    };

    /*const findProductById = (id) => {
        let product = null

        let findProduct = products => products.find(item => item.id === id)

        menu.data.main.forEach((item) => {
            console.log('item', item)


            if(item.subMenu){
                item.subMenu.forEach(sub => {
                    product = findProduct(sub.products)
                })
            }

            if(!product){
                product = findProduct(item.products)
            }
        })



        return  product || {}
    }*/

    const options =
        menuItems?.map(({ name, id: uniqId }: any, index: any) => ({
            id: index,
            name,
            uniqId,
        })) || [];

    const getModifiers = (values) => values.modifiers?.join(",") || "";
    const checkIsProductForPoint = productOpen?.isProductForPoint ? 1 : 0;

    const getQuantity = (page, cart, values, cartItemId) => {
        if (page === "cart" && values?.productId) {
            const product = cart.data.cartItems?.find(
                (item) => item.id === cartItemId,
            );
            return values.quantity - product.quantity;
        }
        return values.quantity;
    };

    return {
        menuItems,
        menuSync,
        isShopPartner,
        options,
        menuReset,
        productSync,
        productOpen,
        getProductPrice,
        getModifiers,
        getQuantity,
        menu,
        checkIsProductForPoint,
        productReset,
        onMenuShopSync,
        onMenuShopSet,
        onMenuSet,
        resolveMenuItems,
    };
};

export default useMenu;
