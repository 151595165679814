import * as t from '../actions/types';

export const initialState = {
    options: [],
    modifiers: [],
    name: null,
    image: null,
    partner: null
}


export default (state = initialState, {type, payload}) => {
    switch (type) {
        case t.OPEN_PRODUCT_START:
            return payload
        case t.CLEAR_PRODUCT_START:
            return initialState

        default:
            return state
    }
}