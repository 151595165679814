import { useRouter } from 'next/router'
import links from 'helper/links'

const navRoute = () => {
    const router = useRouter();

    return {
        navigate: (path, query?) => router.push({
            pathname: path,
            query: {...query}
        }),
        back: () => router.back(),
        home: (query) => {
            router.push({
                pathname: links.abs.home,
                query: {...query}
            })
        },
        query: router.query,
        helpers: {
            checkTemplateHasCoords: () => {

                return (router.route.includes('templateHasCoords'))
            }
        }
    }
}

export default navRoute
