import request from "helper/request";
import combineLink from "helper/combineLink";
import api from "helper/api";
import apiMiniSite from "helper/apiMiniSite";

function isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
}

class MenuService {
    fetchMenu = async (id, config) => {
        const options = {
            setLoading: false,
            withLoader: true,
            json: true,
            ...config,
        };

        const alias = isNumber(id) ? id : "alias-" + id;

        return request
            .get(combineLink(api.partners, alias, "menu"), options)
            .then((data) => {
                return data;
            });
    };

    fetchShopMenu = async (partnerId, menuId, config) => {
        const options = {
            setLoading: false,
            withLoader: true,
            json: true,
            query: {
                onlyTopLevel: 1,
            },
            ...config,
        };

        const alias = isNumber(partnerId) ? partnerId : "alias-" + partnerId;

        return request
            .get(
                combineLink(api.partners, alias, "menu", menuId ? menuId : ""),
                options,
            )
            .then((data) => {
                return data;
            });
    };

    fetchMenuMiniSite = async (baseApi = "", config) => {
        const options = {
            setLoading: false,
            withLoader: true,
            json: true,
            ...config,
        };

        return request
            .get(combineLink(baseApi, apiMiniSite.menu), options)
            .then((data) => {
                return data;
            });
    };

    fetchShopMenuMiniSite = async (baseApi = "", menuId, config = {}) => {
        const options = {
            setLoading: false,
            withLoader: true,
            json: true,
            query: {
                onlyTopLevel: 1,
            },
            ...config,
        };

        console.log("fefe", combineLink(baseApi, apiMiniSite.menu), options);

        return request
            .get(combineLink(baseApi, apiMiniSite.menu, menuId), options)
            .then((data) => {
                return data;
            });
    };
}

const menuService = new MenuService();
export default menuService;
