import {isMobile} from "react-device-detect";

//import UserAgent from 'user-agents';
//const userAgent = new UserAgent();

//const {deviceCategory, platform} = userAgent

export const HEADER_APP_INFO = {
    appName: 'EdostavSpa',
    system: isMobile ? 'mobile' : 'desktop',
};