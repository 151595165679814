import combineLink from "helper/combineLink";
import {api,request} from "helper";
import { CartItemIN } from 'modules/Cart/interfaces/CartItemIN';
import { Product } from 'modules/Partner/interfaces/Product';

class CartService {
    public async addToCart(params: CartItemIN,
                           product: Product,
    ) {
        const { zoneId, quantity } = params;
        const method = Boolean(product) ? 'put' : 'post'
        let options = {
            setLoading: false,
            withLoader: false,
            body: Boolean(product) ? { zoneId, quantity } : params
        }

        return request[method](combineLink(api.cart, (Boolean(product) ? product.id : '')), options)
    };

    public async fetchCart(params?: any) {

        let options = {
            setLoading: false,
            withLoader: false,
            query: params
        }

        return request.get(combineLink(api.cart), options)
    };

    public async updateCartItem(id: string | number, data: CartItemIN) {

        let options = {
            setLoading: false,
            withLoader: false,
            body: data
        }

        return request.put(combineLink(api.cart, id), options)
    }

    public async removeCartItem(id: string | number, zoneId: number) {
        let options = {
            setLoading: false,
            withLoader: false,
            query: {
                zone: zoneId,
            },
        }

        return request.delete(combineLink(api.cart, id), options)
    }

    public async clearCart() {
        let options = {
            setLoading: false,
            withLoader: false,
        }

        return request.delete(combineLink(api.cart), options)
    };
}

const cartService = new CartService();
export default cartService
