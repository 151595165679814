import each from "lodash/each";

const createLinks = (links) => {
    let result = {
        paths: {},
        abs: {},
    };

    each(links, (link, key) => {
        result.paths[key] = link;
        result.abs[key] = `/${link}`;
    });

    return result;
};

const links = createLinks({
    home: "",
    partners: "",
    profile: "user/profile",
    authRequest: "auth",
    authCode: "auth/code",
    cart: "cart",
    myOrders: "user/orders",
    order: "order",
    map: "map",
    settings: "settings",
    discount: "promotions",
    review: "review",
    reviewMy: "user/review",
    address: "user/address",
    faq: "faq",
    myBonus: "user/bonus",
    banners: "container",
    search: "search",
    partnerPage: "partner-page",
    agreement: "agreement",
    ofertaPartners: "oferta-partners",
    privacy: "privacy",
    advpolicy: "advpolicy",
    about: "about",
    partnerSingIn: "partner",
    ui: "storybook-static/index.html?path=/docs/ui-addressform--main",
});

export default links;
