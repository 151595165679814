import * as t from '../actions/types';

export const initialState = {
    query: '',
    data: [],
    isLoading: false,
    error: null,
};

export default (state = initialState, {type, payload}) => {
    switch (type) {
        case t.FETCH_SEARCH_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case t.FETCH_SEARCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: payload.data,
                query: payload.query,
            };
        case t.FETCH_SEARCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case t.CLEAR_SEARCH_SUCCESS:
            return initialState;
        default:
            return state
    }
}