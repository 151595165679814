import { useCallback } from 'react'
import {useSelector} from "react-redux";
import { useAppDispatch, RootState } from "store";
import {changeFilter, resetFilter} from 'modules/Partner/store/filters/actions';
import { objectFilter } from 'helper';

const useFilter = () => {
    const dispatch = useAppDispatch();
    const filters = useSelector((state: RootState) => state.filters);



    const onChangeFilter = useCallback(async (data) => {
        await dispatch(changeFilter(data));
    }, []);


    const onResetFilter = useCallback((data) => {
        dispatch(resetFilter(data));
    }, []);

    const getActiveFilter = useCallback((data?: object) => {

        return objectFilter(data || filters, score => Boolean(score))
    }, [filters]);

    const isHasFilter = useCallback(() => {

        return Boolean(Object.keys(getActiveFilter()).length)
    }, [filters]);


    return {
        filters,
        onChangeFilter,
        onResetFilter,
        getActiveFilter,
        isHasFilter
    }
}

export default useFilter
