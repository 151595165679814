import * as t from '../actions/types';

export const initialState = {
    data: {
        id: null as number | null,
        name: '',
        email: '',
        phone: null as null | number,
        regDate: null as null | number,
        bonuses: {
            rub: 0, manat: 0, som: 0
        },
        ordersWithoutReview: 0,
        sex: '',
        birthday: '',
        isPushEnabled: true,
        isEmailEnabled: false,
    },
    isFetching: true,
    error: null,
};

export type ProfileType = typeof initialState;

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case t.ADD_ORDERS_WITHOUT_REVIEWS:
            return {
                ...state,
                ordersWithoutReview: payload,
            };
        case t.DECREASE_ORDERS_WITHOUT_REVIEW:
            return {
                ...state,
                ordersWithoutReview: state.ordersWithoutReview - 1,
            };
        case t.SET_CURRENT_USER:
            return {
                ...state,
                data: {...payload},
            };
        case t.SHOW_LOADER:
            return {
                ...state,
                isFetching: true,
            };
        case t.HIDE_LOADER:
            return {
                ...state,
                isFetching: false,
            };
        case t.UPDATE_USER_POINTS:
            return {
                ...state,
                bonuses: payload
            }
        default:
            return state;
    }
};

export default reducer;
