import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form'
//import modules from "modules";
import partner from 'modules/Partner/store/partner/reducer';
import partners from 'modules/Partner/store/partners/reducer';
import filters from 'modules/Partner/store/filters/reducer';
import menu from 'modules/Partner/store/menu/reducer';
import partnersNew from 'modules/Partner/store/partnersNew/reducer';
import productOpen from 'modules/Partner/store/productOpen/reducer';
//import currency from 'modules/Partner/store/currency/reducer';
import restaurantsCategories from 'modules/Partner/store/restaurantsCategories/reducer';

import cart from 'modules/Cart/store/cart/reducer';

import map from 'modules/Map/store/map/reducer';
import address from 'modules/Map/store/address/reducer';
import cities from 'modules/Map/store/cities/reducer';

import order from 'modules/Order/store/order/reducer';
import myOrders from 'modules/Order/store/myOrders/reducer';

import banner from 'modules/Promotion/store/banner/reducer';
import bannerContainer from 'modules/Promotion/store/bannerContainer/reducer';
import promoCode from 'modules/Promotion/store/promoCode/reducer';
import promoGifts from 'modules/Promotion/store/promoGifts/reducer';
import specialPromoGifts from 'modules/Promotion/store/specialPromoGifts/reducer';

import auth from 'modules/User/store/auth/reducer';
import profile from 'modules/User/store/profile/reducer';

import reviews from 'modules/Review/store/reviews/reducer';
import bonus from 'modules/Bonus/store/bonus/reducer';

import search from 'modules/Search/store/search/reducer';
import { REHYDRATE } from 'redux-persist'


/*import Partner from 'modules/Partner/store';
import User from 'modules/User/store';
import Order from 'modules/Order/store';
import Map from 'modules/Map/store';
import Cart from 'modules/Cart/store';
import Promotion from 'modules/Promotion/store';*/

//const storeModules = [Partner, User, Order, Map, Cart, Promotion]

const rootReducer = combineReducers({
    partner,
    partners,
    filters,
    menu,
    partnersNew,
    productOpen,
    restaurantsCategories,
    cart,
    map,
    order,
    myOrders,
    banner,
    profile,
    promoCode,
    promoGifts,
    specialPromoGifts,
    auth,
    address,
    reviews,
    bannerContainer,
    bonus,
    search,
    cities,
    //currency,
    //...getReducers(storeModules),
    form: formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
