import request from 'helper/request';
import combineLink from 'helper/combineLink';
import api from 'helper/api';
import apiMiniSite from 'helper/apiMiniSite';

function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }


class PartnerService {
    fetchPartners = async (params, config = {}) => {
        //console.log('fetchPartners')
        let options = {
            setLoading: false,
            withLoader: true,
            json: true,
            query: params,
            ...config
        }


        return request.get(combineLink(api.partners), options)
            .then((data) => {
                //console.log('datadata', data)

                return data
            })
    };
    fetchPartner = async (id, config) => {

        let options = {
            setLoading: false,
            withLoader: true,
            json: true,
            ...config
        }

        //console.log('optionsoptions', options)

        const alias = isNumber(id) ? id : 'alias-'+ id


        return request.get(combineLink(api.partners, alias), options)
            .then((data) => {
                //console.log('datadata', data)

                return data
            })
    };

    fetchPartnerMiniSite = async (baseApi = "", config?) => {
        const options = {
            setLoading: false,
            withLoader: true,
            json: true,
            ...config,
        }


        return request.get(combineLink(baseApi, apiMiniSite.partner), options)
            .then((data) => {
                //console.log('datadata', data)

                return data
            })
    };
}

const partnerService = new PartnerService();
export default partnerService

