import {parseDomain, fromUrl} from "parse-domain";
const {cities} =  require("data/db");

const cityHelper = () => {


    const removeCity = (url = '') => {


        const urlParse = parseDomain(fromUrl(url))

        //console.log('urlParseurlParseurlParse', urlParse)

        if(!urlParse){
            return null
        }

        let urlNewArr = []

        if(urlParse?.labels?.length === 2) {
            urlNewArr = urlParse.labels;
        } else if(urlParse?.labels?.length === 3) {
            urlNewArr = [...urlParse.labels];
            delete urlNewArr[0];
        } else {
            urlNewArr = null
        }

        if(urlNewArr){
            urlNewArr = urlNewArr.join('.')
        }


        return urlNewArr
    }

    const replaceCity = (url, city) => {
        if(!city) {
            return parseDomain(fromUrl(url)).labels.join('.')
        }

        const urlParse = parseDomain(fromUrl(url))

        let urlNewArr = []

        if(urlParse.labels.length === 2) {
            urlNewArr = [city, ...urlParse.labels];
        } else if(urlParse.labels.length === 3) {
            urlNewArr = [...urlParse.labels];
            urlNewArr[0] = city
        } else {
            urlNewArr = null
        }

        if(urlNewArr){
            urlNewArr = urlNewArr.join('.')
        }


        return urlNewArr
    }

    const getCityFromUrl = (url) => {
        let domainFromUrlParse = parseDomain(fromUrl(url))?.labels?.filter(item => item !== 'www')
        const cityFromUrl = domainFromUrlParse?.length === 3 ? domainFromUrlParse[0] : null

        return cityFromUrl
    }

    const getCityObjFromUrl = (url) => {
        const cityNameFromUrl = getCityFromUrl(url)
        const cityObjFromUrl = cityNameFromUrl && cities.find(item => Boolean(item.alias.includes(cityNameFromUrl)))

        return cityObjFromUrl
    }


    return {
        replaceCity,
        getCityFromUrl,
        removeCity,
        getCityObjFromUrl
    }

}

export default cityHelper
