import request from 'axios'
import mapValues from 'lodash/mapValues'
import {logOutAction} from "../modules/User/store/auth/actions";
import {store} from "store";
import NProgress from "nprogress";
import {HEADER_APP_INFO} from "helper/constans";
//import {NotificationManager} from "react-notifications";



let services = {
    api: ''
}

const isServer = typeof window === "undefined"

/*const DEVICE_ID = 'UQBOMgk3YgryYDoeGt3:APA91bFASriFCQqk_0JcOluABcBmHQnQZFmJ17K1V4HNxj6ZzsZtjjMMYL8OaEInNYPmJmFutphxHCJG1KZccerSH12zQatAqxdkRkbqXsgXgtfxi7a4SL33Q58_sUkc0BG'*/

export const getRequestsFormattedAddress = (mapData = {}, token) => ({
    'address-house': mapData.addressObj?.house ? encodeURIComponent(mapData.addressObj.house) : '',
    //'address-city': 1,
    'address-status': mapData.latitude ? 1 : 0,
    ...(token ? { 'user-token': token } : {}),
    ...(mapData.addressObj?.cityId ? { 'address-city': mapData.addressObj.cityId } : {}),
    ...(mapData.latitude ? { 'address-lat': mapData.latitude } : {}),
    ...(mapData.longitude ? { 'address-lon': mapData.longitude } : {}),
    ...(mapData.addressObj?.street
        ? { 'address-street': encodeURIComponent(mapData.addressObj.street) }
        : {}),
});

const resolveUrl = (url) => {
    if (/^http|^\//.test(url)) {
        return url
    } else {
        return services.api + url.replace(/^\//, '')
    }
}


const doRequest = (url, options = {}) => {
    const mapData = store?.getState().map?.data
    const addressHeader = getRequestsFormattedAddress(mapData)

    options.headers = {
        ...addressHeader,
        ...options.headers,
    }

    const setHeader = (headers) => {
        options.headers = {
            ...options.headers,
            ...headers
        }
    }

    setHeader(HEADER_APP_INFO)

    if (!options.method) {
        options.method = 'get'
    }

    /*options.timeout = {
        response: 25000,  // Wait 25 seconds for the server to start sending,
        deadline: 120000, // but allow 2 minute for the file to finish loading.
    }*/



    if (!options.noToken) {
        const token = store?.getState().auth?.data.token
        if(token){
            setHeader({
                'user-token': token,
            })
        }
    }

    setHeader({
        'device-id': `${store?.getState().auth?.data.deviceId}`,
    })

    if (options.query) {
        options.params = options.query
    }

    if (options.body) {
        options.data = mapValues(options.body, value => value === true ? 1 : value)
    }

    if (options.files) {
        let formData = new FormData()
        const photoData = options.files?.map(file => {
            formData.append('file[]', file)
        })

        if(options.data) {
            options.data = {...options.data, ...photoData}
        }
    }







    //const req = request[options.method.toLowerCase()](resolveUrl(url), options)

    options.method = options.method.toLowerCase()
    options.url = resolveUrl(url)
    //console.log('optionsrequset', options)
    const req = request(options)

    /*if (options && !options.noCredentials) {
        //req.withCredentials()
    }*/

    //req.set(addressHeader)

    //req.set(HEADER_APP_INFO)
    //req.retry(2, 5000, [401, 404])

    /*if (options.headers) {
        req.set(options.headers = {...addressHeader, ...options.headers})
    }*/

    /*if (options.timeout) {
        //req.set(options.timeout)
        req.timeout(options.timeout)
    }*/

    /*if (!options.noToken) {
        req.set({
            'user-token': `${store?.getState().auth?.data.token || ''}`,
        })
    }*/
    /*req.set({
        'device-id': `${store?.getState().auth?.data.deviceId}`,
    })*/

    /*if (options.query) {
        req.query(options.query)
    }*/

    /*if (options.body) {
        req.send(mapValues(options.body, value => value === true ? 1 : value))
    }*/

    /*if (options.file) {
        req.attach('file[]', options.file)
    }

    if (options.files) {
        each(options.files, (file, i) => {
            req.attach(`file[${i}]`, file)
        })
    }*/

    // if (options.token) {
    //   req.set({ Authorization: `Bearer ${options.token}` })
    // }
    //
    // if (options.getToken) {
    //   const token = options.getToken(getState())
    //   if (token) {
    //     req.set({ Authorization: `Bearer ${token}` })
    //   }
    // }
    //
    // if (options.auth) {
    //   req.auth(...options.auth)PartnersElement
    // }

    return req
}

/*export const getFieldsErrors = (err) => {

    const { errors, title, type } = err
    let ret = {}
    if ('validation_error' === type && errors) {
        mapValues(errors, (val, key) => {
            let str = ''
            if (isArray(val)) {
                str += val.join('; ')
            } else {
                str += val
            }
            ret[key] = str
        })
    }
    return ret
}*/

/*const showNotifications = (err) => {
    //console.log('err', err)

    //NotificationManager.error('', err.message, 5000);
    /!*
    return;
    if(!err){
        //actions.notifications.push({message: 'Something went wrong\n Please try later'})

        return
    }
    let msg = []
    const { error, title, type } = err
    const fieldErrors = getFieldsErrors(err)
    let notification = { title: title, message: '', type: type }

    mapValues(fieldErrors, (val, key) => {
        msg.push(key + ': ' + val)
    })

    if (error) {
        msg.push(error)
    }
    notification.message = msg.join('\n')*!/
    //actions.notifications.push(notification)
}*/

const requestByMethod = (method) =>
    (url, options) => {
        options = options || {}

        //console.log('requestByMethod', options)

        if (typeof(options.before) === 'function') {
            options.before()
        }

        if (typeof(options.setLoading) === 'function') {
            options.setLoading(true)
        }

        if (options.withLoader) {
            if(!isServer){
                NProgress.start()
            }
            if (method === 'get') {
                //actions.ui.showLineLoader()
            } else {
                //actions.ui.showDataLoader()
            }
        }

        return new Promise((resolve, reject) =>
            doRequest(url, { ...options, method })
                .then(async (data = {}, err) => { // request end
                    const body = data.data
                    const statusCode = data.status
                    //console.log(err, data)
                    /*console.log(`${method.toUpperCase()}`, statusCode, url, {
                        data,
                        err,
                        body,
                    })*/



                    /*if (err && err.timeout) {
                        //showNotifications({ error:'Connection timeout. Can\'t execute request. Please try later', title:'', type:'' })
                        reject(err)
                        //return
                    }*/


                    /*if (!data) {
                        //showNotifications(err)
                        reject(err)
                        //return
                    }*/


                    if (statusCode === 401) {
                        //console.log('statusCode', 1111)
                        store?.dispatch(logOutAction())
                    }

                    if (statusCode === 413) {
                        //showNotifications({ error:'Entity Too Large', title:'', type:'' })
                    }



                    if (typeof(options.setLoading) === 'function') {
                        options.setLoading(false)
                    }

                    if(statusCode && !body) {
                        resolve({status: statusCode})
                    }else{
                        resolve(body)
                    }
                })
                .catch((data) => {
                    console.log('errerrerr', data)

                    reject(data?.response?.data)
                })
                .then(() => {
                    if (typeof(options.always) === 'function') {
                        options.always()
                    }

                    if (typeof(options.setLoading) === 'function') {
                        options.setLoading(false)
                    }

                    if (options.withLoader) {
                        if(!isServer){
                            NProgress.done();
                        }
                        /*if (method === 'get') {
                            //actions.ui.hideLineLoader()
                        } else {
                            //actions.ui.hideDataLoader()
                        }*/
                    }
                })
        )
    }



doRequest.get = requestByMethod('get')
doRequest.post = requestByMethod('post')
doRequest.patch = requestByMethod('patch')
doRequest.put = requestByMethod('put')
doRequest.delete = requestByMethod('delete')
doRequest.upload = requestByMethod('post')


export default doRequest
