import { createStore, applyMiddleware } from "redux";
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import { useDispatch } from "react-redux";
import { Store } from "redux";
import cookies from "js-cookie";
import {
    nextReduxCookieMiddleware,
    wrapMakeStore,
} from "next-redux-cookie-wrapper";
import { parseDomain, ParseResultType } from "parse-domain";
import cityHelper from "helper/cityHelper";
import { DESTROY_SESSION } from "modules/User/store/auth/actions/types";


//export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch();

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== "production") {
        const { composeWithDevTools } = require("redux-devtools-extension");
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export interface State {
    tick: string;
}
const reducer = (state, action) => {
    let isServer = typeof window === "undefined"
    let map = !isServer ? cookies.get('map') ? JSON.parse(cookies.get('map')) : null : null
    let auth = !isServer ? cookies.get('auth') ? JSON.parse(cookies.get('auth')) : null : null
    //let formOrderValues = !isServer ? cookies.get('form.order.values') ? JSON.parse(cookies.get('form.order.values')) : null : null
    const {getCityObjFromUrl} =  cityHelper();
    const cityObjFromUrl = isServer ? null : getCityObjFromUrl(window.location.host)
    const isHasCoords = Boolean(map?.data?.latitude && map?.data?.longitude)




    let hydrateObj = {}


    if(action.payload){
        const {partnersNew, specialPromoGifts, banner, restaurantsCategories, partners, partner, menu, map} = action.payload
        //if(!isServer && !map?.data?.latitude && !map?.data?.longitude) {
        /*hydrateObj = {
            ...(form ? {form} : {}),
        }*/

        if(!isHasCoords) {
            hydrateObj = {
                ...(partnersNew ? {partnersNew} : {}),
                ...(specialPromoGifts ? {specialPromoGifts} : {}),
                ...(banner ? {banner} : {}),
                ...(restaurantsCategories ? {restaurantsCategories} : {}),
                ...(partners ? {partners} : {}),
                ...(partner ? {partner} : {}),
                ...(menu ? {menu} : {}),
            }

            if(cityObjFromUrl){
                hydrateObj = {
                    ...hydrateObj,
                    map: {
                        data: {
                            address: cityObjFromUrl.name,
                            addressObj: {
                                cityId: cityObjFromUrl.id,
                                city: cityObjFromUrl.name
                            }
                        }
                    }
                }
            }
        }



        /*hydrateObj = {
            ...(partnersNew ? {partnersNew} : state.partnersNew ? {partnersNew: state.partnersNew} : {}),
            ...(specialPromoGifts ? {specialPromoGifts} : state.specialPromoGifts ? {specialPromoGifts: state.specialPromoGifts} : {}),
            ...(banner ? {banner} : state.banner ? {banner: state.banner} : {}),
            ...(restaurantsCategories ? {restaurantsCategories} : {}),
            ...(partners ? {partners} : {}),
        }*/


        if(isHasCoords && state) {
            const {partnersNew, specialPromoGifts, banner, restaurantsCategories, partners, cities, cart, address} = state

            hydrateObj = {
                ...action.payload,
                ...hydrateObj,
                ...(partnersNew ? {partnersNew} : {}),
                ...(specialPromoGifts ? {specialPromoGifts} : {}),
                ...(banner ? {banner} : {}),
                ...(restaurantsCategories ? {restaurantsCategories} : {}),
                ...(partners ? {partners} : {}),
                ...(cities ? {cities} : {}),
                ...(cart ? {cart} : {}),
                ...(address ? {address} : {}),
            }
        }

    }

    if (action.type === DESTROY_SESSION) {
        //state = undefined;
    }

    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
                ...hydrateObj,
                ...(map ? {map} : {}),
                ...(auth ? {auth} : {}),
                //...(!isNotCoords ? {partners: state.partners} : {})
                //...(formOrderValues ? {form: {order: {values:formOrderValues}}} : {})
            }
        default:
            return rootReducer(
                state,
                action,
            )
    }

    /*return {
        extraReducers: {
            ...state,
            [HYDRATE]: (state, {payload}) => ({
                ...state,
                ...payload.page,
            }),
        },
    }

    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
        //if (state.count.count) nextState.count.count = state.count.count // preserve count value on client side navigation
        return nextState;
    } else {
        if (action.type === DESTROY_SESSION) {
            state = undefined;
        }

        return rootReducer(state, action);
    }*/
};

export const getDomain = () => {
    let domain = {}
    let isServer = typeof window === "undefined"
    if(!isServer) {
        const parseResult = parseDomain(location.hostname);

        if(parseResult.labels.length === 3) {
            parseResult.labels.shift()
        }
        domain = parseResult.labels.join('.')
    }

    return domain
}

export let store = null

const makeStore = wrapMakeStore((context) => {
   let isServer = typeof window === "undefined"
    /* const {removeCity, getCityFromUrl, replaceCity} =  cityHelper();
    let hostnameFromUrl = context?.req?.headers.host
    const domainForCookie = removeCity(hostnameFromUrl)*/

    //const domain = isServer ? domainForCookie : getDomain()
    const domain = getDomain()

    /*console.log('domaindomain', domain)

    if(isServer){}
    const { req, res } = context
    const COOKIE_VERSION = process.env.NEXT_PUBLIC_COOKIE_VERSION
    res.headers.set('Cookie', `cookie_version=${COOKIE_VERSION}`)*/

    /*const COOKIE_VERSION = process.env.NEXT_PUBLIC_COOKIE_VERSION

    setCookies('cookie_version', COOKIE_VERSION, { req, res})
    removeCookies('map', { req, res, domain: 'makhachkala.edostav.local'});*/

        store = createStore(
            reducer,
            bindMiddleware([
                thunkMiddleware,
                nextReduxCookieMiddleware({
                    /*subtrees: [
                        "map.data.latitude",
                        "map.data.longitude",
                        "map.data.addressObj",
                        "auth.data.token",
                        "auth.data.deviceId",
                    ],*/
                    secure: false,
                    compress: false,
                    ignoreStateFromStaticProps: false,
                    ...(domain ? {domain} : {}),
                    subtrees: [
                        "map",
                        "auth",
                        //"form.order.values.Address.number",
                        //"form.order.values.Order.text",
                    ],
                })
            ]),
        )
        return store
    }
);


/*const makeStore = wrapMakeStore(() =>
    configureStore({
        reducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().prepend(
                nextReduxCookieMiddleware({
                    //subtrees: [`${pageSlice.name}.counter`],
                })
            ),
    })
);*/

export const wrapper = createWrapper<Store<State>>(makeStore);
