import combineLink from "helper/combineLink";
import {api,request} from "helper";
import apiMiniSite from "helper/apiMiniSite";

class MapService {
    fetchCities = async (data) => {
        let options = {
            setLoading: false,
            withLoader: false,
            query: data
        }

        return request.get(combineLink(api.city), options)
    };

    fetchCitiesMiniSite = async (data, baseApi = "") => {
        let options = {
            setLoading: false,
            withLoader: false,
            query: data
        }

        return request.get(combineLink('', apiMiniSite.city), options)
    };
}
const orderService = new MapService();
export default orderService
